import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Sunset Ridge Golf Kulübü
			</title>
			<meta name={"description"} content={"Günün son ışıklarının gökyüzünü boyadığı nefes kesici bir manzaranın kalbinde yer alan Sunset Ridge, eşi benzeri olmayan bir golf deneyimi sunuyor."} />
			<meta property={"og:title"} content={"Ana Sayfa | Sunset Ridge Golf Kulübü"} />
			<meta property={"og:description"} content={"Günün son ışıklarının gökyüzünü boyadığı nefes kesici bir manzaranın kalbinde yer alan Sunset Ridge, eşi benzeri olmayan bir golf deneyimi sunuyor."} />
			<meta property={"og:image"} content={"https://bosphorusbeauty.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bosphorusbeauty.com/img/5950392.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bosphorusbeauty.com/img/5950392.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bosphorusbeauty.com/img/5950392.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0" sm-padding="40px 0" background="--color-primary" quarkly-title="About-8">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="100%" />
			<Box
				width="70%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				padding="90px 64px 90px 64px"
				background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(110, 37, 189, 0.51) 0%),rgba(0, 0, 0, 0) url(https://bosphorusbeauty.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box"
				lg-width="100%"
				sm-padding="32px 32px 32px 32px"
				lg-padding="48px 64px 90px 64px"
				md-padding="48px 48px 90px 48px"
			>
				<Text margin="0px 0px 24px 0px" font="--headline1" color="--light" sm-font="--headline2">
					Sunset Ridge Golf Kulübü
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					font="--base"
					color="--light"
					max-width="400px"
					sm-max-width="none"
				>
					Günün son ışıklarının gökyüzünü boyadığı nefes kesici bir manzaranın kalbinde yer alan Sunset Ridge, eşi benzeri olmayan bir golf deneyimi sunuyor. Golf tutkunları için bir sığınak olan kulübümüz, zorlu oyunu doğanın eşsiz güzellikteki arka planıyla birleştiriyor. Sunset Ridge sadece oynanacak bir yer değil, golf efsanelerinizin doğduğu yerdir.
				</Text>
				<Link
					href="/contacts"
					color="--dark"
					text-decoration-line="initial"
					padding="9px 24px 10px 24px"
					background="--color-light"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="0.5px"
					border-radius="6px"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				width="30%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				position="relative"
				justify-content="center"
				lg-width="100%"
				lg-justify-content="flex-start"
				background="rgba(27, 38, 40, 0.6)"
			>
				<Image
					src="https://bosphorusbeauty.com/img/2.jpg"
					display="block"
					max-height="80%"
					position="absolute"
					left="-200px"
					width="500px"
					height="500px"
					object-fit="cover"
					lg-width="100%"
					lg-height="auto"
					lg-max-height="600px"
					sm-max-height="400px"
				/>
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="20px 0"
			sm-padding="40px 0"
		>
			<Text font="--headline2" margin="10px 0 0 0">
				Sunset Ridge Golf Kulübü'nün Büyüsünü Yaşayın
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Sunset Ridge, zorlu olduğu kadar görsel olarak da çarpıcı bir golf deneyimi sunmaya kendini adamıştır. Sahalarımız doğal araziye kusursuz bir şekilde uyum sağlayacak şekilde tasarlanmıştır ve her beceri seviyesinden golfçüye sakin manzaraların ve batan güneşin muhteşem renklerinin tadını çıkarırken yeteneklerini test etme şansı sunar.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-primary"
			quarkly-title="Advantages/Features-20"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Sunset Ridge Golf Kulübü Neden Eşsizdir?
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--light"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								- Unutulmaz Parkur Tasarımı:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Hem meydan okuma hem de cazibe sağlayan benzersiz delik düzenleriyle sahalarımız, hayal gücünü yakalayan bir ortamda oyunlarını yükseltmek isteyenlere hitap etmektedir.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--darkL2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								- Başka Hiçbir Şeye Benzemeyen Akşam Turları:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Değişen ışığın oyununuza yepyeni bir boyut kattığı güneş batarken oynamanın büyüsünü yaşayın.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								- Birinci Sınıf Misafirperverlik:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Kulüp binamız, golfçülerin gurme yemekler ve bir dizi içecekle gevşeyebilecekleri bir rahatlama ve arkadaşlık cennetidir.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								- Oyununuza Odaklanın:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Son teknoloji antrenman tesislerinden profesyonel eğitime kadar, becerilerinizi geliştirmek için gereken her şeyi sunuyoruz.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					sm-height="300px"
					lg-height="auto"
					lg-width="100%"
				>
					<Image
						height="589px"
						box-shadow="0 10px 20px -10px rgba(43, 45, 84, 0.15),0 0px 20px 0 rgba(43, 45, 84, 0.05)"
						src="https://bosphorusbeauty.com/img/3.jpg"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						sm-height="300px"
						object-fit="cover"
						lg-display="block"
						lg-height="auto"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});